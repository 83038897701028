import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Peer from 'simple-peer';

// import Typography from '@material-ui/core/Typography';
// import Header from './Header';
// import Footer from './Footer';

// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
//
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(10),
    paddingTop: theme.spacing(10),
  },
  paperC: {
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  section1: {
    margin: theme.spacing(2, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
});


const Custom_webrtc = props => {
  const { classes, user ,match} = props;
  const { id } = match.params;

  console.log(props)
  console.log(id)

  if(id=="2"){
    // get video/voice stream
    // navigator.mediaDevices.getUserMedia({
    //   video: true,
    //   audio: false
    // }).then(gotMedia).catch(() => {})

    // function gotMedia (stream) {
      // var peer1 = new Peer({ initiator: true, stream: stream })
      var peer2 = new Peer()

      // peer1.on('signal', data => {
      //   peer2.signal(data)
      // })

      peer2.on('signal', data => {
        console.log(data)
        // peer1.signal(data)
      })

      peer2.on('stream', stream => {
        // got remote video stream, now let's show it in a video tag
        var video = document.createElement("video");//querySelector('video')
        document.body.appendChild(video);

        if ('srcObject' in video) {
          video.srcObject = stream
        } else {
          video.src = window.URL.createObjectURL(stream) // for older browsers
        }

        video.play()
      })

  }else if(id=="1"){
    // get video/voice stream
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    }).then(gotMedia).catch(() => {})

    function gotMedia (stream) {
      var peer1 = new Peer({ initiator: true, stream: stream })
      var peer2 = new Peer()

      peer1.on('signal', data => {
        console.log(data)
        peer2.signal(data)
      })

      peer2.on('signal', data => {
        peer1.signal(data)
      })

      peer2.on('stream', stream => {
        // got remote video stream, now let's show it in a video tag
        var video = document.createElement("video");//querySelector('video')
        document.body.appendChild(video);

        if ('srcObject' in video) {
          video.srcObject = stream
        } else {
          video.src = window.URL.createObjectURL(stream) // for older browsers
        }

        video.play()
      })
    }
  }

  return (
    <React.Fragment>
    </React.Fragment>
  );
}

Custom_webrtc.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Custom_webrtc);
