import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
// import Alert from '@material-ui/lab/Alert';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import detectEthereumProvider from '@metamask/detect-provider';

function toHex(stringToConvert) {
  return stringToConvert
    .split('')
    .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('');
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(10),
    paddingTop: theme.spacing(10),
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
});


const Web3 = props => {
  const { classes, match ,user , CONF } = props;
  const [value, setValue] = React.useState("Walletを連携中…");
  var { role,chPrefix,id } = match.params;

  React.useEffect(() => {
    if(user)start();
  },[])

  const vars = {};

  // const buy = (event) => {
  //   var el = document.getElementById( 'paypal1' )
  //   el.click();
  // };

  function open(){
    // window.open( CONF.paypal.url+'myaccount/autopay/connect/'+user.subscr_id );
    const callback = CONF.firebase.functions+"/portal?custamer_id="+user.custamer_id;
    console.log(callback);
    // window.open( callback );
    window.location.href = callback;
  }

  async function start(){
    vars.provider = await detectEthereumProvider();
    if (!vars.provider) {
      throw new Error('Please install MetaMask');
      return;
    }

    try{
      vars.res = await Axios.get(CONF.firebase.functions+"/getNonceToSign?id="+user.uid);

      await vars.provider.request({ method: 'eth_requestAccounts' });

      vars.sig = await vars.provider.request({
              method: 'personal_sign',
              params: [
                `0x${toHex(vars.res.data.nonce)}`,
                vars.provider.selectedAddress,
              ],
            });

      vars.res = await Axios.get(CONF.firebase.functions+"/verifySignedMessage?id="+user.uid+"&address="+vars.provider.selectedAddress+"&signature="+vars.sig);
      // return <Redirect to={"/Room/" + role + "/" + chPrefix + "/" + id} />
      window.location.href = window.location.origin + "/Room/" + role + "/" + chPrefix + "/" + id;
      setValue("Wallet連携完了");

    } catch(err){
        setValue("Wallet連携を解除")
    }
    // vars.res = await Axios.get("http://localhost:5001/dp-xr-staging/us-central1/getNfts?id="+user.uid);
    // window.open('about:blank', '_self').close();
    // window.close();
    // console.log(vars);
  }

  if(!user) return <Redirect to={"/Login/target/Web3"} />

  // console.log(user)

  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/Web3" Dp={{}}/>
      <Grid container justify="center" alignItems="center" direction="row" className={classes.root}>
        <Grid className={classes.caption}>
          <Typography p={2} component="h2" variant="h5" gutterBottom>
            {value}
            {
              (value=="Wallet連携完了")?
              <>
              <br />
              <br />
              <Link href="" onClick={()=>{window.location.href = window.location.origin + "/Room/" + role + "/" + chPrefix + "/" + id}}>
               戻る
              </Link>
              </>
              :""
            }
          </Typography>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

Web3.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Web3);
