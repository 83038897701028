import React, { useState, useEffect } from 'react';
//import { Route, BrowserRouter as Router } from 'react-router-dom';
import { BrowserRouter, Route, Switch ,Redirect} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
//import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
//import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme';
import Home from './Home';
import Faq from './Faq';
import License from './License';
import Room from './Room';
import Mypage from './Mypage';
import Web3 from './Web3';
import Login from './Login';
import Decoder from './Decoder';
import Test from './Test';
import Custom_webrtc from './Custom_webrtc';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import CONF from './config';

firebase.initializeApp(CONF.firebase);
const db = firebase.firestore();
const strage = firebase.storage();

function App() {
  const [user, setUser] = useState(null);

  // useEffect(()=>{
  //   // function script(path){
  //   //   const head = document.getElementsByTagName('head')[0];
  //   //   const scriptUrl = document.createElement('script');
  //   //   scriptUrl.type = 'text/javascript';
  //   //   scriptUrl.src = path;//'js/index.js';
  //   //   head.appendChild(scriptUrl);
  //   // }
  //   // script('js/index.js');
  //   // script('js/shader.js');
  //
  //   // const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
  //   //   async (user) => {
  //   //     setUser(user);
  //   //     if (user) {
  //   //       const refUser = db.collection("users").doc(user.uid);
  //   //       const newValue = { lastAccessed:firebase.firestore.FieldValue.serverTimestamp() };
  //   //       const doc = (await refUser.get()).data();
  //   //       if (!doc || !doc.name) {
  //   //         newValue.name = user.displayName;
  //   //       }
  //   //       await refUser.set(newValue, { merge: true });
  //   //     }
  //   //   }
  //   // );
  //
  //   // return unregisterAuthObserver;
  // }, []);

  useEffect(()=>{

    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (userValue) => {
        console.log(userValue);

        if (userValue) {
          //------------------------------------
          // メアド確認済み
          //------------------------------------
          if (userValue.emailVerified) {
            const result = await firebase.auth().currentUser.getIdTokenResult(true);
            console.log(result.claims);

            const refUser = db.collection("users").doc(userValue.uid);
            const doc = (await refUser.get()).data();
            console.log(doc);

            if(doc){
              if(doc.data){
                userValue.address = doc.address;
                userValue.txn_type = doc.txn_type;
                userValue.eventType = doc.eventType;
                userValue.subscr_id = doc.subscr_id;
                userValue.custamer_id = doc.custamer_id;


                try {
                  // const url = await storageRef.child("license_base/data0.2.0.json").getDownloadURL();//.then(async function(url) {
                  // console.log(url);
                  //
                  // const res = await Utl.Axios.get(url);
                  // const items = res.data;
                  // userValue.data = res.data;
                  //
                  // console.log(res);

                } catch (error) {
                  userValue.data = "error";
                  console.log(error);
                }

              }

            }else{
              userValue = null;
            }

            // const refUser = db.collection("users").doc(userValue.uid);
            // //const newValue = { lastAccessed:firebase.firestore.FieldValue.serverTimestamp() };
            // const doc = (await refUser.get()).data();
            //
            // if(doc.data){
            //   var json = await Utl.AxiosWrap.get(doc.data)
            //   console.log(json);
            // }

            // if (!doc || !doc.name) {
            //   newValue.name = userValue.displayName;
            // }
            // await refUser.set(newValue, { merge: true });

          }
          //------------------------------------
          // メアド未確認
          //------------------------------------
          else {
              userValue.sendEmailVerification()
                  .then(() => {
                    //window.history.pushState(null, null, '#/Sendmail/ok');
                    //return <Redirect to="/Sendmail"/>
                      //alert('${user.email}宛に確認メールを送信しました');
                      //showMessage('Send confirm mail', '${user.email}宛に確認メールを送信しました');
                  })
                  .catch((error) => {
                    setUser(null);
                      //window.history.pushState(null, null, '#/Sendmail/ng');
                      // alert('${user.email}宛に確認メールを送信できませんでした');
                      //showMessage('[Error] Can not send mail', `${user.email}宛に確認メールを送信できませんでした: ${error}`);
                  });
            }
          }
          setUser(userValue);
      }
    );

    return unregisterAuthObserver;
  }, []);

  const params = { user, db , CONF , strage};
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Route exact path="/" render={(props) => <Home {...props} {...params} />} />
        <Route exact path="/DesignPad" render={(props) => <Redirect to="/" />} />
        <Route exact path="/Home" render={(props) => <Home {...props} {...params} />} />
        <Route exact path="/Faq" render={(props) => <Faq {...props} {...params} />} />
        <Route exact path="/License" render={(props) => <License {...props} {...params} />} />
        <Route exact path="/Room/:role/:chPrefix/:id" render={(props) => <Room {...props} {...params} />} />
        <Route exact path="/Custom_webrtc/:id" render={(props) => <Custom_webrtc {...props} {...params} />} />
        <Route exact path="/Mypage" render={(props) => <Mypage {...props} {...params} />} />
        <Route exact path="/Test" render={(props) => <Test {...props} {...params} />} />
        <Route exact path="/Web3/:role/:chPrefix/:id" render={(props) => <Web3 {...props} {...params} />} />

        <Route exact path="/login" render={(props) => <Login {...props} {...params} />} />
        <Route exact path="/login/cmd/:encoded" render={(props) => <Login {...props} {...params} />} />
        <Route exact path="/login/target/:target" render={(props) => <Login {...props} {...params} />} />
        { // We need to mount the Decoder component only after the user info became available.
          (user) ?
            <Route exact path="/decode/:encoded" render={(props) => <Decoder {...props} {...params} />} />
            : ""
        }


      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
