import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Footer from './Footer';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(10),
    paddingTop: theme.spacing(10),
  },
  paperC: {
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  section1: {
    margin: theme.spacing(2, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
});


const Faq = props => {
  const { classes, user } = props;
  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/faq" />
      <Grid container direction="column"
  justify="flex-start"
  alignItems="flex-start" className={classes.root}>
        <Grid>
            <Typography component="h2" variant="h4" gutterBottom>
              FAQ
            </Typography>
            <div className={classes.section1}>
            <Typography component="h2" variant="h6" gutterBottom>
              Q:旧DesignPadのＩＤ・パスワードは、withSparks(新バージョン)で利用できますか？
            </Typography>
            </div>
            <Divider variant="middle" />
            <div className={classes.section2}>
            <Typography component="h2" variant="body1" gutterBottom>
              A:旧DesignPadのＩＤ・パスワードは、withSparks(新バージョン)で利用できません。改めて購入する必要がございます。
            </Typography>
            </div>
            <br/>
            <br/>
            <div className={classes.section1}>
            <Typography component="h2" variant="h6" gutterBottom>
              Q:エラーが発生した場合、どうしたらよいですか？
            </Typography>
            </div>
            <Divider variant="middle" />
            <div className={classes.section2}>
            <Typography component="h2" variant="body1" gutterBottom>
            購入完了メールに記載された、カスタマーサービスのメールアドレスへ、ご購入時の「氏名」「メールアドレス」および「自動請求ID」と以下の情報をお送り頂けますようお願いいたします。
            </Typography>
            <Typography component="h2" variant="h6" gutterBottom>
            1.エラーが発生する環境について
            </Typography>
            <Typography component="h2" variant="body1" gutterBottom>
            最近及び最後にインストールまたはアップデートしたソフトウェアやハードウェア及び関連する項目があれば合わせてお送り下さい。
            ※出来る限り詳細にご報告頂けますようお願いいたします。
            </Typography>
            <Typography component="h2" variant="h6" gutterBottom>
            2.エラー画面のスクリーンショット
            </Typography>
            <Typography component="h2" variant="body1" gutterBottom>
            撮りたい画面が表示された状態で以下の操作を行います。
            </Typography>
            <Typography component="h2" variant="body1" gutterBottom>
            WINDOWSの場合
            </Typography>
            <Typography component="h2" variant="body2" gutterBottom>
            ↓キーボードの「Print Screen」キーを押下します。
            </Typography>
            <Typography component="h2" variant="body2" gutterBottom>
            ↓「スタート」メニューから、「プログラム」→「アクセサリ」と選択して「ペイント」を起動します。
            </Typography>
            <Typography component="h2" variant="body2" gutterBottom>
            ↓ペイントが起動したら、「編集」メニューから「貼り付け(P)　Ctrl+V」を選択して貼り付けます。
            </Typography>
            <Typography component="h2" variant="body2" gutterBottom>
            画像を保存し、メールに添付します。
            </Typography>
            <Typography component="h2" variant="body1" gutterBottom>
            Macの場合
            </Typography>
            <Typography component="h2" variant="body2" gutterBottom>
            コマンド＋Shift＋3キーを同時におすと、デスクトップに画像として保存されます。
            画像をメールに添付します。
            </Typography>
            <Typography component="h2" variant="h6" gutterBottom>
            3.OSの種類、パソコンの種類、メモリ、HDD、グラフィックカードなどの付属品・増設品の情報
            </Typography>
            <Typography component="h2" variant="body1" gutterBottom>
            本ソフトをご利用になるパソコンをお買い求めになった電気店等、または、製造メーカーへご確認下さい。
            ※出来る限り詳細にご報告頂けますようお願いいたします。
            ※技術的な問題の検証には、しばらく時間を要することも予想されますが、対処方法が判明した場合、随時ご連絡致します。
           </Typography>
           </div>
        </Grid>
      </Grid>
      <Footer/>
    </React.Fragment>
  );
}

Faq.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Faq);
