import React from 'react';
import PropTypes from 'prop-types';
import { withStyles ,createMuiTheme,ThemeProvider} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Footer from './Footer';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import AppleIcon from '@material-ui/icons/Apple';
import { deepOrange } from '@material-ui/core/colors';

// npm install --save-dev @iconify/react @iconify-icons/mdi
import { Icon, InlineIcon } from '@iconify/react';
import microsoftWindows from '@iconify-icons/mdi/microsoft-windows';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PlayCircleFilledIcon from '@material-ui/icons/YouTube';

const theme = createMuiTheme({
  palette: {
    secondary: deepOrange,
  },
});

const styles = theme => ({
  media: {
    height: 200,
  },
  root: {
    // maxWidth: 345,
    flexGrow: 1,
    width: "100%",
    margin: 0,
    padding: theme.spacing(2),
    // paddingTop: theme.spacing(10),
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
  paperC: {
    textAlign: "center",
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
  /*! 埋め込み動画をレスポンシブ対応させる */
  video:{
    position: "relative",
    // height: 0,
    width: "100%",
    //height: "100%",
    padding: "0 0 56%",
    // padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  // iframe:{
  //   paddingTop: theme.spacing(8),
  //   left: 0,
  //   position: "absolute",
  //   width: "100%",
  //   height: "100%",
  //   // padding: theme.spacing(2),
  //   color: theme.palette.text.secondary,
  // },
  youtube:{
    position: "absolute",
    width: "100%",
    height: "100%",
    // padding: theme.spacing(5),
  },
  head:{
    position: "relative",
  },
  headimg:{
    width: "100%",
    margin:"0",
    padding:"0",
  },
  title:{
    width: "300px",
  },
  copy :{
    position: "absolute",
    // textAlign: "center",
    // justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    margin:"0",
    padding:"0",
    // height: "100%",
    top: "50%",
    left: "50%",
    "-ms-transform": "translate(-50%,-50%)",
    "-webkit-transform": "translate(-50%,-50%)",
    transform: "translate(-50%,-50%)",
    "font-weight": "bold", /*太字に*/
    "font-size": "2em",/*サイズ2倍*/
    // "font-family" :"Quicksand, sans-serif",/*Google Font*/
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Home = props => {
  const { classes, user } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // var playerWindow = document.getElementById("iframe").contentWindow;
    // playerWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/Home" />
      <div style={{height:50}}/>
      <Grid container spacing={2} className={classes.root} style={{
        alignItems: 'center',
      }}>
      <Grid item xs={12} sm={6} style={{
        justifyContent: 'center',
        textAlign: "center",
      }} >
      <Typography style={{
          // color: "#555",
      }} variant="h4" gutterBottom>
        <b>
          あなたの仮想空間を創り
        </b>
      </Typography>

      <Typography style={{
          // color: "#555",
      }} variant="h4" gutterBottom>
        <b>
          世界にライブ配信しよう！<br/>
        </b>
      </Typography>

      <img className={classes.title} id="img" src="img/logo.png" />
      <Typography style={{
          color: "#555",
      }} variant="h6" gutterBottom>
        β評価版（無料）ダウンロード！
      </Typography>

      <Grid container spacing={2}>

      {/*
      <Grid item xs={4} sm={4} >
        <Button className={classes.button} style={{
          width:"100%",
            backgroundColor: "#000000",
            borderRadius: 10,
        }}
        size="large" variant="contained" color="primary" startIcon={<PlayCircleFilledIcon style={{ fontSize: 30 }}/>}onClick={handleClickOpen}>
          PLAY
        </Button>
        </Grid>
        */}

        <Grid item xs={6} sm={6} >
        <Button className={classes.button} style={{
          width:"100%",
            backgroundColor: "#00487F",
            borderRadius: 10,
        }}
         size="large" variant="contained" color="secondary"　startIcon={<AppleIcon style={{ fontSize: 30 }}/>} onClick={()=>{window.open("https://apps.apple.com/jp/app/designpad/id1565655823", '_blank');}}>
          mac
        </Button>
        </Grid>
        <Grid item xs={6} sm={6} >
        <Button className={classes.button} style={{
          width:"100%",
          backgroundColor: "#00487F",
            borderRadius: 10,
        }}
         size="large" variant="contained" color="secondary"　startIcon={<Icon icon={microsoftWindows} style={{ fontSize: 30 }}/>} onClick={()=>{window.open("https://www.microsoft.com/ja-jp/p/designpad/9nk3smn7p16v", '_blank');}}>
          Win
        </Button>
        </Grid>
        </Grid>
      </Grid>


      <Grid item xs={12} sm={6}style={{
        textAlign: 'center',
      }}>
      <img style={{
        width:"100%",
        maxWidth: 450,
      }}id="img" src="img/header3.png" />
      </Grid>
      </Grid>

      <div>
        <Dialog fullWidth maxWidth="xl" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            　
          </DialogTitle>
          <DialogContent>
          <div className={classes.video} >
            <iframe className={classes.youtube} id="iframe" src="https://www.youtube.com/embed/14bckrTFoX4?rel=0&enablejsapi=1&loop=1&playlist=14bckrTFoX4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          </DialogContent>
        </Dialog>
      </div>

      <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} sm={4}>
        <Card style={{height:"100%"}}>
          <CardMedia
            className={classes.media}
            image="img/img3.png"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              3D仮想空間からライブ配信！
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              360動画や3Dデータの仮想空間にグリーンバック合成で出演!
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card style={{height:"100%"}}>
          <CardMedia
            className={classes.media}
            image="img/img1.png"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              シンプル直感操作！
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              サムネールのクリックで直感的にスイッチングやエフェクト操作!
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card style={{height:"100%"}}>
          <CardMedia
            className={classes.media}
            image="img/img2.png"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              100種超の多彩なエフェクト！
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              BPMに合わせた音同期効果やリアルタイム操作のドラッグエフェクト！
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperC}>
          <Typography component="h2" variant="h5" gutterBottom>
            推奨環境
          </Typography>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>内容</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.calories}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      </Grid>
      <Footer/>
    </React.Fragment>
  );
}

function createData(name, calories) {
  return { name, calories};
}

const rows = [
  createData('OS', 'macOS BigSur及びWindows 10'),
  createData('モニター(操作画面)', '1024 x 768 以上'),
  createData('モニター(プロジェクター)', '800 x 600 以上（セカンドモニター）'),
  createData('認証方法', 'インターネット接続(pay pal決済)'),
  createData('対応形式', '.mp4(H.264圧縮)'),
];

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
