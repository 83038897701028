import React from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import FirebaseUIAuth from 'react-firebaseui-localized';
import { Redirect } from 'react-router-dom';
import Header from './Header';
import Loading from './Loading';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Axios from 'axios';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(10),
    textAlign: "center",
    width: "100%",
    }
  });

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  	credentialHelper: 'none',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: (result) => {
      const { additionalUserInfo, credential } = result;
      if (additionalUserInfo && credential
        && credential.providerId === firebase.auth.TwitterAuthProvider.PROVIDER_ID) {
          console.log("Twitter user name=", additionalUserInfo.username);
      }
      return false;
    }
  }
};

function Login(props) {
  const { classes, match, user,CONF } = props;
  const { target, encoded } = match.params;

  if(user == 0) {
    return <React.Fragment>
      <Header />
      <Loading />
    </React.Fragment>

  }if (!user) {
    return <React.Fragment>
      <Header />
      <div className={classes.root}>
      <FirebaseUIAuth
        lang="ja"   //ここを'ja'にするだけ
        config={uiConfig}
        auth={firebase.auth()}
        firebase={firebase}
      />

      <br/>
      ©2021 {CONF.info.name} All Rights Reserved.
      {(!CONF.web)
      ?<Link href="#" onClick={()=>{Axios.get("/openExternal/"+CONF.firebase.web+"/license")}}>
      利用規約
      </Link>
      :""
      }
      </div>
    </React.Fragment>
  }

  // if (encoded) {
  //   return <Redirect to={"/decode/"+encoded} />
  if (encoded) {
    return <Redirect to={"/"+decodeURIComponent(encoded)} />
  } else if (target) {
    return <Redirect to={"/"+target} />
  }
  return <Redirect to={"/"} />
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
