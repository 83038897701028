import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Footer from './Footer';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

import { segmentBackground, applyBlur, applyImageBackground } from'virtual-bg';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(10),
    paddingTop: theme.spacing(10),
  },
  paperC: {
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  section1: {
    margin: theme.spacing(2, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
});


const Faq = props => {
  const { classes, user } = props;

  React.useEffect(() => {
  async function init(){
    const video = document.getElementById('video')
    navigator.mediaDevices.getUserMedia(
      { video: { facingMode: "user" } }
    ).then( ( stream ) => {
      video.srcObject = stream;
    } );

    //
    // const inputVideoElement = document.querySelector('#inputVideoElement');
    // const outputCanvasElement = document.querySelector('#output_canvas');
    //
    // let myStream = await navigator.mediaDevices.getUserMedia({
    //       video: true
    // });
    //
    // inputVideoElement.srcObject = myStream;
    //
    // // segments foreground & background
    // segmentBackground(inputVideoElement, outputCanvasElement);
    //
    // // applies a blur intensity of 7px to the background
    // applyBlur(20);
    //
    // // applies an image background
    // const image = new Image();
    //
    // let canvas = document.createElement("canvas");
    // let context = canvas.getContext('2d');
    //
    // //background color
    // context.beginPath();
    // context.fillStyle = 'rgb(255,0,0)';
    // context.fillRect(0, 0, canvas.width, canvas.height);
    //
    // image.src = canvas.toDataURL();//'./img/black.png'
    // applyImageBackground(image);

  }
  init()
},[])

  return (
    <React.Fragment>
      <Header user={user} login="/Login/target/faq" />
      <Grid container direction="column"
  justify="flex-start"
  alignItems="flex-start" className={classes.root}>
        <Grid>
            <Typography component="h2" variant="h4" gutterBottom>
              TEST
            </Typography>
            <div>
                <video id="video" muted="muted" autoPlay playsinline></video>
            </div>
        </Grid>
      </Grid>
      <Footer/>
    </React.Fragment>
  );
}

Faq.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Faq);
