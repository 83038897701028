import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/auth";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const styles = {
  root: {
    flexGrow: 1,
  },
  bar: {
    backgroundColor: "#000000",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

function MyAppBar(props) {
  const { classes, user, login ,style,Dp} = props;
  const [drawer, setDrawer] = useState(false);
  const [stat, setStat] = useState(false);

  const handleMenu = event => {
    setDrawer(true);
  };
  const handleClose = () => {
    setDrawer(false);
  };
  const logout = event => {
    console.log("logout");
    firebase.auth().signOut();
    setStat(Math.random());
    // window.location.reload();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (val) => {
    var title = val.currentTarget.getAttribute("title")
    if(title){
      var func = Dp.menu.find(element => element.title == title);
      func.click();

      // console.log(val.currentTarget);
    }
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  var renderMenu = "";
  var renderMenu2 = "";

  // if(Dp){
    renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
      {
        user ?
        [
          <MenuItem onClick={async ()=>{
            window.location.href = window.location.origin+"/login/cmd/Mypage";
          }} key="MenuItem1">ライセンス</MenuItem>,
          <MenuItem  key="MenuItem2" onClick={logout} >ログアウト</MenuItem>,
        ]
        // <Button color="inherit" onClick={logout}>Logout</Button>
        // : <Button color="inherit" to={login || "/login"} component={Link}>Login</Button>
        : <MenuItem to={login || "/login"} component={Link}>ログイン</MenuItem>
          //   {Dp.menu.map((val) => (
          //       <MenuItem onClick={handleMenuClose} title={val.title} key={Math.random()}>{val.title}</MenuItem>
          //   ))}
        }
      </Menu>
    );

    renderMenu2 = (
      <IconButton aria-label="display more actions" edge="end" color="inherit" onClick={handleProfileMenuOpen} size="small">
        <MoreIcon />
      </IconButton>
    )
  // }



  return (
    <div className={classes.root}>
      <AppBar className={classes.bar}>
        <Toolbar>
          <IconButton className={classes.menuButton} onClick={handleMenu} color="inherit" aria-label="Menu" size="small">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            withSparks
          </Typography>
          {renderMenu2}
        </Toolbar>
      </AppBar>

      {renderMenu}

      <Drawer open={drawer} onClose={handleClose}>
        <List>
          <ListItem button to="/" component={Link}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button to="/license" component={Link}>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="License" />
          </ListItem>
          <ListItem button to="/faq" component={Link}>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="Faq" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

MyAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyAppBar);
