import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Typography from '@material-ui/core/Typography';
import Header from './Header';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import QRCode from 'qrcode';
// import * as Stripe from 'stripe'

// import Link from '@material-ui/core/Link';
// import Alert from '@material-ui/lab/Alert';
import Axios from 'axios';
import Utl from './Utl';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import AgoraRTC from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm-sdk'

import { segmentBackground, applyBlur, applyImageBackground } from'virtual-bg';


var init = true;
var initNft = true;

function getURL(role,chPrefix){
}

// Utl.CONF.firebase.functions = "http://localhost:5001/dp-xr-staging/us-central1"
// Utl.CONF.firebase.functions = "http://192.168.0.15:5001/dp-xr-staging/us-central1"

// Utl.CONF.firebase.functions = "https://9fe8-240f-36-ca39-1-35f4-424c-4e51-fdd3.ngrok.io/dp-xr-staging/us-central1"


const w = Utl.CONF.defaultWidth/2;
const h = Utl.CONF.defaultHeight/2;

const styles = theme => ({
  hide:{
    display :"none"
  },
  center:{
    justifyContent:"center"
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  root: {
    flexGrow: 1,
    // padding: theme.spacing(1),
    paddingTop: "56px",//theme.spacing(8),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '80%',
    },
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
   media: {
     height: 280,
   },
  button: {
    margin: theme.spacing(1),
  },

  player: {
    height: "100%",
    width: "100%",
    "z-index":"2000",
    "pointer-events": "none",
    position:"fixed"
  },
  caption: {
    textAlign: "center",
    width: "100%",
  },
  video: {
    "background-color": "#000",
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "auto",
    // "max-width":w+"px",
    // width: w+"px",
    // height: h+"px",
  },
  qr:{
    display: "block",
    width: "100%",
    "max-width":"200px",
    height: "auto",
    margin : "0 auto"
  },
  video2: {
    "background-color": "#000",
    display: "block",
    // "margin-left": "auto",
    // "margin-right": "auto",
    // textAlign: "center",
    width: "100%",
    height: "auto",
  },
  frame: {
    position: 'absolute',
    frameborder:'no',
    width:'100%',
    height:'100%',
  },
  paypal: {
    display: "none",
  },
});

// var videoStream = (navigator.userAgent.indexOf("Firefox") > -1)? videoFromDiv.mozCaptureStream():videoFromDiv.captureStream();
const qr = {
  "dj":{},
  "room":{}
};

var timer ,timer_room,token_live_room,token_rtcend_live,token_live_dj , token_room,rtm,rtmChannel,update,geometry1,totalWidth,token_live_stage,client_live_stage,canvasTrack_room_audio
let scene, camera, renderer,mixer,load;
const Dp = {};

const Room = props => {
  var { classes, user , match , CONF} = props;
  var { role,chPrefix,id } = match.params;
  // var { role,chPrefix } = Utl.param2json(json);
  // if(!user)user = {};
  function check(para){
      if(!user)return false;
      return user[para]
  }

  if(initNft && check("address")){
    Axios.get(Utl.CONF.firebase.functions+"/getNfts?id="+user.uid)
      .then(res=>{
        setNfts(res.data.result);
      });
    initNft = false;
  }

  const tracks = [0];
  if(!role)role = "audience";
  if(!chPrefix)chPrefix = "";

  //stage
  const [state, setState] = React.useState(0);
  const [mic, setMic] = React.useState(true);
  const [stage, setStage] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  //tab
  const [tabvalue, setTabValue] = React.useState(0);
  const [nfts,setNfts] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    loadQue = [];
    setTabValue(newValue);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  Dp.agoraUsers = users;

  // async function getSize(el){
  //   return el.firstChild.videoWidth * h / el.firstChild.videoHeight;
  // }

  Dp.getTotalWidth = async ()=>{
    let sum=document.getElementById("rtc-mixer").width;

    await Promise.all(Dp.agoraUsers.map(async (user,key) => {
      // const el = document.getElementById("local-player"+key+"-avator").firstChild;
      if(user.videoElement)sum += user.videoElement.videoWidth * h / user.videoElement.videoHeight;//await getSize(user.videoElement);
    }));
    return sum;
  };


  async function agora(role,channelName){
    init = false;
    var videoFromDiv = document.getElementById('videoFromDiv');//document.createElement( 'video' );
    videoFromDiv.style.width = "100%";
    videoFromDiv.style.height = "100%";

    document.getElementById("local-player").append(videoFromDiv);

      ["dj","room"].map(chPrefix=>{
        ["rtc","host"].map(async role=>{
          const url = "https://"+getURL(role,chPrefix);
          try {
            qr[chPrefix][role] = await QRCode.toDataURL(url)
              setState(Math.random());
            console.log(qr);
          } catch (err) {
            console.error(err)
          }
        })
      })

      const appID = Utl.CONF.agora.id;//""//process.env.APP_ID;
      var uid = 0//req.query.uid || 0

      const url = Utl.CONF.firebase.functions+"/buildTokenWithUid?uid=";
      async function getToken(token,channelName,chPrefix){
        if(!token){
          var res = await Axios.get(url+channelName+"&role="+role+"&ch=_"+chPrefix)
          token = res.data;
          // if(token != "checkout") token = JSON.parse(token);
        }
        return token;
      }

      function getTimer(channelName){
        return setInterval(()=>{
          Axios.get(url+channelName);
        }, Utl.CONF.agora.usage);
      }

      var client_live_room,client_rtcend_live,client_live_dj;
      async function getCli(token,chPrefix){
        return [
          AgoraRTC.createClient({mode: "live", codec: "vp8"}),
          await getToken(token,channelName,chPrefix),
        ]
      }


    if(role=="host" || role=="rtc"){

        token_live_stage = await getToken(token_live_stage,channelName,"stage","audience");

        if(token_live_stage == "checkout"){
          setState("checkout");
          return;
        }

        // const tracks = tracks;
        client_live_stage = AgoraRTC.createClient({mode: "live", codec: "vp8"});
        client_live_stage.setClientRole("audience");


        client_live_stage.on("user-published", async (user, mediaType) => {
          await client_live_stage.subscribe(user, mediaType);
          console.log("subscribe success");

          if (mediaType === "video") {
            // await user.videoTrack.play("stage-player");
            user.needUpdate = true;
            setStage([user]);
            timer_room = getTimer(channelName);
          }
          if (mediaType === "audio") {
            user.audioTrack.play();
          }
        });

        client_live_stage.on("user-unpublished", (user, type) => {
          console.log("unpublished", user, type);
          if (type === "audio") {
            if(user.audioTrack)user.audioTrack.stop();
          }
          if (type === "video") {
            setStage([]);
            if(timer)clearInterval(timer);
          }
        });

        await client_live_stage.join(appID, token_live_stage[2], token_live_stage[0]);//appId, name, token, null);
        // await client_room.publish(tracks)

        Dp.videoFromDiv = videoFromDiv;

        const localStream = await navigator.mediaDevices.getUserMedia({video: { width: w, height: h }, audio: true})
        Dp.videoFromDiv.srcObject = localStream;
        Dp.videoFromDivVideoTrack = localStream;
        Dp.videoFromDivAudioTrack = localStream.getAudioTracks()[0];

        if(role=="host"){
          [client_rtcend_live,token_rtcend_live] = await getCli(token_rtcend_live,chPrefix);
          if(token_rtcend_live == "checkout") return setState("checkout");

          client_rtcend_live.setClientRole(role);
          uid = await client_rtcend_live.join(appID, token_rtcend_live[2], token_rtcend_live[0]);
          // await client.publish(tracks);
          console.log(role,uid,appID, channelName, token_rtcend_live)
        }

        token_room = await getToken(token_room,channelName,"rtc_"+chPrefix);
        if(token_room == "checkout"){
          setState("checkout");
          return;
        }

        // const tracks = tracks;
        const client_room = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});

        client_room.on("user-published", async (user, mediaType) => {
          await client_room.subscribe(user, mediaType);
          console.log("subscribe success");

          if (mediaType === "video") {
            timer_room = getTimer(channelName);
            // user.videoTrack.play("local-player"+Dp.agoraUsers.length);
            user.needUpdate = true;
            setUsers((prevUsers) => {
              return [...prevUsers, user];
            });
          }
          if (mediaType === "audio") {
            user.audioTrack.play();
          }
        });

        client_room.on("user-unpublished", (user, type) => {
          console.log("unpublished", user, type);
          if (type === "audio") {
            if(user.audioTrack)user.audioTrack.stop();
          }
          if (type === "video") {
            if(timer)clearInterval(timer);
            setUsers((prevUsers) => {
              return prevUsers.filter((User) => User.uid !== user.uid);
            });
          }
        });

        await client_room.join(appID, token_room[2], token_room[0]);//appId, name, token, null);
        // await client_room.publish(tracks)



        //3d
        // let scene, camera, renderer;
        // var el = document.getElementById("local-player")
        // scene = new THREE.Scene();
        // scene.background = new THREE.Color(0x4de95a);

        // const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444 );
				// hemiLight.position.set( 0, 20, 0 );
				// scene.add( hemiLight );
        //
        // camera = new THREE.PerspectiveCamera(45, w / h, 0.1, 1000);
        //
        // camera.position.z = w/300*3-2.1;
        // camera.position.y = h/300+0.6;
        // camera.position.x = 0;
        // camera.rotation.x = -0.2;


        // const devicePixelRatio = window.devicePixelRatio || 1;

        renderer = new THREE.WebGLRenderer({
          canvas: document.getElementById("rtc-mixer")
        });
        renderer.setSize(w, h);
        renderer.setPixelRatio(1);

        renderer.domElement.style.width = "auto";
        renderer.domElement.style.height = "100%";

        // const gridHelper = new THREE.GridHelper(20, 20);
        // scene.add(gridHelper);

        const clock = new THREE.Clock();

        // const loader = new GLTFLoader();
				// loader.load( window.location.origin+'/samples/Xbot.glb', function ( gltf ) {
				// 	var model = gltf.scene;
				// 	scene.add( model );
        //
        //   mixer = new THREE.AnimationMixer(gltf.scene)
        //   const actionA = mixer.clipAction(gltf.animations[3])
        //   actionA.play()
        // });


        function render() {
          requestAnimationFrame(render); //再度render関数を実行
          if(scene){
            if(Dp.videoFromDivTexture)Dp.videoFromDivTexture.needsUpdate = true;
            renderer.render(scene, camera); //シーン, カメラをもとに描画
            if(mixer)mixer.update(clock.getDelta());
          }
        }
        render();



        Dp.publish = async (three)=>{
          var streamCanvas_room = document.getElementById("rtc-mixer");
          // if(document.getElementById('stage').firstChild) streamCanvas_room = renderer.domElement;//document.getElementById('stage').firstChild;

          var streamCanvasType_room = streamCanvas_room.getContext("2d");
          var mergedStream_room = streamCanvas_room.captureStream();
          var canvasTrack_room = await AgoraRTC.createCustomVideoTrack({mediaStreamTrack:mergedStream_room.getVideoTracks()[0]});
          canvasTrack_room_audio = await AgoraRTC.createCustomAudioTrack({mediaStreamTrack:Dp.videoFromDivAudioTrack});

          await client_room.publish([canvasTrack_room,canvasTrack_room_audio]);

          setState(Math.random());

          // var s = 50,
          var streamCanvas,streamCanvasType,mergedStream,canvasTrack;

          if(role=="host") {
            streamCanvas = document.getElementById("live-mixer");
            // if(document.getElementById('stage').firstChild) streamCanvas = renderer.domElement;//document.getElementById('stage').firstChild;
            streamCanvasType = streamCanvas.getContext("2d");
            mergedStream = streamCanvas.captureStream();
            canvasTrack = await AgoraRTC.createCustomVideoTrack({mediaStreamTrack:mergedStream.getVideoTracks()[0]});
            await client_rtcend_live.publish([canvasTrack]);
            sendusers();
          }
          // else{
          //   s = -50;
          // }

          // if(!document.getElementById('stage').firstChild)
          animate();

          async function animate() {
            requestAnimationFrame(animate);
            if(streamCanvasType_room){
              streamCanvasType_room.drawImage(
                videoFromDiv,
                0,0,
                // s,
                // s,
                streamCanvas_room.width,
                streamCanvas_room.height
              );
            }

            if(role=="host") {
              const getliveMixSize = ()=>{
                return [
                  streamCanvas.width * streamCanvas_room.width / totalWidth,
                  streamCanvas.height
                ];
              }
              const [x,y] = getliveMixSize();

              streamCanvasType.drawImage(
                streamCanvas_room,
                0,
                0,
                x,
                y
              );

              let current = x;
              Dp.agoraUsers.map(async (user,key) => {
                // const el = document.getElementById("local-player"+key+"-avator").firstChild;
                if(user.videoElement){
                  const w = streamCanvas.width * (user.videoElement.videoWidth * h / user.videoElement.videoHeight) / totalWidth;//vawait getSize(el) / ;
                  streamCanvasType.drawImage(
                    user.videoElement,
                    current,
                    0,
                    w,
                    y
                  );
                  current += w;
                }
              })
            }
            // render();
            // renderer.setAnimationLoop( render );
          }

        };


        // init = false;
        // setState(Math.random());

      }

      if(role=="host" || role=="audience"){
        await joinRTM();
        async function joinRTM(){
          //rtm ole,uid,appID, channelName, token
          rtm = await AgoraRTM.createInstance(appID);
          rtm.on('ConnectionStateChanged', (...args) => {
            console.log('ConnectionStateChanged ', ...args)
            // if(args[1]=="LOGIN_SUCCESS"){
            //   console.log(rtmChannel)
            // }
          });
          rtm.on('MessageFromPeer', (...args) => {
            var json = JSON.parse(args[0].text);
            if(update)update(json);
            console.log('MessageFromPeer ', ...args)
          });

          // if(role=="audience") await rtm.login({ uid: role , token: token_live_room[1]});
          if(role=="host") await rtm.login({ uid: role , token: token_rtcend_live[1]});
          console.log('AgoraRTM client login success');

          const att = await rtm.getChannelAttributes(channelName)
          console.log(att)

          //rtm channel
          rtmChannel = await rtm.createChannel(channelName);
          rtmChannel.on('ChannelMessage', (...args) => {
            console.log('ChannelMessage ', ...args)
            // var json = JSON.parse(args[0].text);
            // if(update)update(json.users);
          });
          rtmChannel.on('MemberJoined', (...args) => {
            console.log('MemberJoined ', ...args)
            if(args[0]=="audience")sendusers();
          });
          rtmChannel.on('MemberLeft', (...args) => {
            console.log('MemberLeft ', ...args)
          });

          await rtmChannel.join();
          const mems = await rtmChannel.getMembers();
          console.log(mems);

        }
      }
      // init = false;
      setState(Math.random());
      Dp.publish();
    }

  async function sendusers(end){
    var num = 1;
    if(end)num=0
    // const text = '{"users":'+(users.length+num)+',"ch":"'+chPrefix+',"total":"'+await Dp.getTotalWidth()+',"width":"'+w+',"height":"'+h+'"}';
    // const total = await Dp.getTotalWidth();
    totalWidth = await Dp.getTotalWidth();
    const json = {users:users.length+num,ch:chPrefix,total:totalWidth,width:w,height:h};
    if(role=="host" && rtm) await rtm.sendMessageToPeer({ text: JSON.stringify(json)}, "audience");
  }

  window.addEventListener('beforeunload', async (event) => {
    await sendusers(true);
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  });

  // function getRemortVideo(id){
  //   var el = document.getElementById(id).firstChild;
  //   if(el)return;
  //   return document.getElementById(id+"-avator").firstChild;
  // }

  React.useEffect(() => {
    Dp.agoraUsers = users;

    function update(user,id,send){
      if(user.needUpdate){
        user.videoTrack.play(id);
        const el = document.getElementById(id).firstChild;
        el.firstChild.addEventListener('playing', (el) => {
          user.videoElement = el.currentTarget;
          if(id=="stage-player") el.currentTarget.style.position = "relative";
          else document.getElementById(id+"-avator").appendChild(el.currentTarget);
          if(send)sendusers();
        });
        user.needUpdate = false;
      }
    }

    if(!users.length && !init)sendusers();
    users.map((user,key) => {
      update(user,"local-player"+key,true);
    })

    stage.map((val) => {
      update(val,"stage-player");
    })

    // me.map((val) => {
    //   if(document.getElementById("local-player-me").firstChild)document.getElementById("local-player-me").firstChild.remove();
    //   document.getElementById("local-player-me").appendChild(val);
    // })

    if(init){
      agora(role,id)
    }//Dp.agoraInit();
  }, [users,stage,nfts]);


  function rend(msg){
    return (
        <React.Fragment>
          <Header user={user} login="/Login/target/mypage" Dp={{}}/>
          <Grid container justifyContent="center" alignItems="center" direction="row" className={classes.root}>
          <Grid className={classes.caption}>
          {msg}
          </Grid>
          </Grid>
        </React.Fragment>
      )
  }

      if(state == "checkout"){
        return rend((
          <Grid className={classes.caption}>
              <br/>
              <Typography p={2} component="h4" variant="h7" gutterBottom>
                License購入でスマホカメラからライブ入力できます。
              </Typography>
              <Button variant="contained" color="primary"　onClick={()=>{
                const s = document.getElementById("root").style;
                s.left = "";//win[0].x+"px";
                s.width = "100%";//(win[0].width-10)+"px";
                window.location.href = window.location.origin+"/login/cmd/Mypage";
              }} className={classes.button}>
                購入ページへ
              </Button>
            </Grid>
        ));

      }else{
        load = async function(path){
          const promise = new Promise(resolve => {
            // scene.clear();
            const loader = new THREE.ObjectLoader();
            loader.load(
            	// resource URL
            	path,

            	// onLoad callback
            	// Here the loaded data is assumed to be an object
            	async function ( obj ) {
            		// Add the loaded object to the scene
            		// scene.add( obj );
                scene = obj;

                let outputCanvasElement = document.createElement("canvas");

                // segments foreground & background
                await segmentBackground(Dp.videoFromDiv, outputCanvasElement);
                // document.body.appendChild(outputCanvasElement);

                // applies a blur intensity of 7px to the background
                await applyBlur(20);

                // applies an image background
                const image = new Image();

                let canvas = document.createElement("canvas");
                let context = canvas.getContext('2d');

                //background color
                context.beginPath();
                context.fillStyle = 'rgb(77, 233, 90)';
                context.fillRect(0, 0, canvas.width, canvas.height);

                image.src = canvas.toDataURL();//'./img/black.png'
                await applyImageBackground(image);


                Dp.videoFromDivTexture = new THREE.CanvasTexture( outputCanvasElement );
                Dp.videoFromDivTexture.minFilter = THREE.LinearFilter;
                Dp.videoFromDivTexture.magFilter = THREE.LinearFilter;
                // Dp.videoFromDivTexture.format = THREE.RGBFormat;

                resolve();

                // loader.parse(path, (obj) => {
                //   scene = obj.scene;
                //   camera = obj.camera;
                //
                //   Dp.videoFromDivTexture = new THREE.VideoTexture( Dp.videoFromDiv );
                //   Dp.videoFromDivTexture.minFilter = THREE.LinearFilter;
                //   Dp.videoFromDivTexture.magFilter = THREE.LinearFilter;
                //   Dp.videoFromDivTexture.format = THREE.RGBFormat;
                //
                //   resolve();
                // })
            	},

            	// onProgress callback
            	function ( xhr ) {
            		console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            	},

            	// onError callback
            	function ( err ) {
            		console.error( 'An error happened' );
            	}
            );
          });

          await promise; // 1 秒待機
          scene.traverse((obj)=>{
            const d = obj.userData;
            if(obj.type == "PerspectiveCamera")camera = obj;

            if(d.bg){
              scene.background = new THREE.Color(parseInt(d.bg, 16));
            }

            if(d.play){
                mixer = new THREE.AnimationMixer(obj)
                const anim = obj.animations.find((v) => v.name.toUpperCase() === d.play.toUpperCase());
                const actionA = mixer.clipAction(anim)
                actionA.play()
            }

            if(d.video){
              if(d.video == "camera"){
                Dp.videoFromDiv.srcObject = Dp.videoFromDivVideoTrack;
                Dp.videoFromDiv.src = null;
              }else{
                Dp.videoFromDiv.srcObject = null;
                if(d.sample)Dp.videoFromDiv.src = window.location.origin+"/samples/"+d.sample ;
              }
              // Dp.videoFromDiv.loop = true;
              Dp.videoFromDiv.play();
              obj.material.map = Dp.videoFromDivTexture;
            }
          })

        }

        return rend((
          <React.Fragment>
            <video id="videoFromDiv" muted="muted" autoPlay playsInline loop></video>
              {stage.map(val => (
                <>
                  <div id="stage-player" className={classes.video} key="stage-playere"></div>
                </>
              ))}
              <br/>
              <Grid className={classes.caption}>
                <AvatarGroup max={4} className={classes.center}>
                  <Avatar className={classes.large}>
                    <canvas id="rtc-mixer" width={w} height={h} >
                      キャンバスの表示内容を説明する代替テキストです。
                    </canvas>
                  </Avatar>
                  {users.map((user,key) => (
                    <Avatar id={"local-player"+key+"-avator"} key={user.uid+"av"} className={classes.large}>
                    </Avatar>
                  ))}
                  </AvatarGroup>
              </Grid>
              <br/>
              <Grid className={classes.caption}>
                <IconButton onClick={()=>{
                  if(!canvasTrack_room_audio)setMic(true);
                  else{
                    setMic(mic==false)
                    canvasTrack_room_audio.setMuted(!mic);
                  }
                }}>
                {!mic?<MicIcon color="primary" />
                  :<MicOffIcon />
                }
                </IconButton>
                { // We need to mount the Decoder component only after the user info became available.
                (navigator.clipboard) ?
                <IconButton onClick={e=>{
                  handleClick(e);
                  if(navigator.clipboard){
                       navigator.clipboard.writeText(window.location.origin + "/Room/" + role + "/" + chPrefix + "/" + id);
                   }
                }}>
                  <ShareIcon color="primary" />
                </IconButton>
                    :""
                }
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    招待リンクをコピーしました!
                  </Alert>
                </Snackbar>
              </Grid>
              <br/>

              {users.map((user,key) => (
                  <div id={"local-player"+key} className={classes.hide} key={user.uid}></div>
              ))}


              <Grid className={classes.captionNoCenter} >
                <Tabs value={tabvalue} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="simple tabs example" centered>
                  <Tab label="サンプル" {...a11yProps(0)} />
                  <Tab label="NFT" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={tabvalue} index={0}>
                  <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                    {[
                        {
                          token_uri: window.location.origin+'/samples/camera/meta.json',
                          chashed:true,
                          action:()=>{load(window.location.origin+'/samples/camera/scene.json')}
                        },
                        {
                          token_uri: window.location.origin+'/samples/avator/meta.json',
                          chashed:true,
                          action:()=>{load(window.location.origin+'/samples/avator/scene.json')}
                        },
                        {
                          token_uri: window.location.origin+'/samples/video/meta.json',
                          chashed:true,
                          action:()=>{load(window.location.origin+'/samples/video/scene.json')}
                        },
                      ].map((tile,key) => (
                        <Cards key={"Cards"+key} classes={classes} data={tile}/>
                    ))}
                    </Grid>
                  </Container>
                </TabPanel>
                <TabPanel value={tabvalue} index={1}>
                <Container className={classes.cardGrid} maxWidth="md">
                  {/* End hero unit */}
                  <Grid container spacing={4}>
                  {[
                    ...nfts].map((tile,key) => (
                      <Cards key={"Cards"+key} Dp={Dp} index={key} classes={classes} data={tile}/>
                  ))}
                  </Grid>
                </Container>

                {
                  // (!user.address)
                  (true)
                    ?<Button variant="contained" color="primary"　onClick={()=>{window.location.href = window.location.origin+"/Login/cmd/" + encodeURIComponent("Web3/" + role + "/" + chPrefix + "/" + id);}}  className={classes.button}>
                      Walletを連携
                    </Button>
                    :""
                }
              </TabPanel>
              </Grid>



              {(role=="host")?
              <>
                <canvas id="live-mixer" className={classes.hide} width={w} height={h}>
                  キャンバスの表示内容を説明する代替テキストです。
                </canvas>
              </>
              :""}
              <br/>
              <div id="local-player" className={classes.hide}></div>

              {
                (false)?
                <>
                  <Typography p={2} component="h4" variant="h6" gutterBottom>
                    QRコードでスマホカメラ入力
                  </Typography>
                    {(typeof qr[chPrefix].rtc == "string")?
                      <img src={qr[chPrefix].rtc} alt="qr-code" className={classes.qr}/>
                    :""}
                    { // We need to mount the Decoder component only after the user info became available.
                    (navigator.clipboard) ?
                      <Button variant="contained" color="primary"　onClick={()=>{
                        if(navigator.clipboard){
                             navigator.clipboard.writeText(getURL("rtc",chPrefix));
                         }
                      }} className={classes.button}>
                        リンクをコピー
                      </Button>
                        :getURL("rtc",chPrefix)
                    }
                  </>
                :""
              }

          </React.Fragment>
        ));
      }
}


var loadQue = [];
const sleep = waitTime => new Promise( resolve => setTimeout(resolve, waitTime) );

function Cards(props) {
  const { classes,data,index,Dp, ...other } = props;
  const [value, setValue] = React.useState(["loading..","loading..",window.location.origin+"/img/black.png"]);

  React.useEffect(() => {
    async function init(){
      if(!data.action){
        data.action = ()=>{
          // load(data.token_uriloaded.external_link);
          load(Utl.CONF.firebase.functions+"/getNftsImg?img=true&url="+encodeURIComponent(data.token_uriloaded.external_link));

          // Axios.get("/downloadNft/"+index)
          // .then(res=>{
          //   if(res.data.data)Dp.openSample(res.data.data);
          // })
        }
      }

      if(!data.chashed)await sleep(1000);//Rate limit対策)
      if(!data.chashed && !data.token_uriloaded)data.token_uri = Utl.CONF.firebase.functions+"/getNftsImg?url="+encodeURIComponent(data.token_uri)
      if(!data.token_uriloaded) {
        const res = await Axios.get(data.token_uri);
        data.token_uriloaded = res.data;
      }

      if(!data.token_uriloaded.image_blob){
        if(!data.chashed){
          var img = data.token_uriloaded.image;
          img = await Axios.get(img,{responseType: 'blob'});
          data.token_uriloaded.image_blob = URL.createObjectURL(img.data);
          data.chashed = true;

        }else{
          data.token_uriloaded.image_blob = value[2];
        }
      }

      // const img = Utl.CONF.firebase.functions+"/getNftsImg?img=true&url="+encodeURIComponent(data.token_uriloaded.image);
      console.log(img)
      setValue([data.token_uriloaded.name,data.token_uriloaded.discription,data.token_uriloaded.image_blob])
      if(loadQue.length > 0){
        if(loadQue.length > 0)loadQue.shift()();
      }
    }
     // init();
    if(loadQue.length == 0) init();
    loadQue.push(init);
  }, []);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardActionArea onClick={data.action}>
          <CardMedia
            className={classes.media}
            image={value[2]}
            title={value[0]}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" className={classes.centerCancel}>
              {value[0]}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.centerCancel}>
              {value[1]}
            </Typography>
          </CardContent>
        </CardActionArea>
        {(data.token_address)?
          <CardActions>
            <Button size="small" color="primary"　onClick={()=>{
              const callback = "testnets.opensea.io/assets/"+data.token_address+"/"+data.token_id;
              console.log(callback);
              // Axios.get("/openExternal/"+callback);
              // window.location.href = callback;
              window.open('https://'+callback, '_blank');
            }}>
              Openseaへ
            </Button>
          </CardActions>:
          ""}
      </Card>
    </Grid>
  );
}

Cards.propTypes = {
  classes: PropTypes.object.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
        <br/>
        <br/>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

Room.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Room);
